export default {
  "nav_our_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our program"])},
  "nav_about_HPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About TPM"])},
  "nav_blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
  "nav_testimonies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimonies"])},
  "nav_contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "nav_view_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View demos"])},
  "nav_locale_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "nav_locale_es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
  "nav_tpm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® login"])},
  "banner_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan, learn and grow together..."])},
  "banner_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® will be their partner at home, we will help them get through their everyday school tasks and together we'll build a brilliant future for your kids."])},
  "banner_free_trial_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request demo"])},
  "banner_contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "our_prog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our program"])},
  "our_prog_content_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
  "our_prog_content_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software covers all content from Preschool to the 12th grade, providing instruction 24 hours a day, 365 days of the year"])},
  "our_prog_bilingual_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilingual"])},
  "our_prog_bilingual_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programs are made so that the parents can get involved even without knowledge of the English language."])},
  "our_prog_software_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software"])},
  "our_prog_software_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Includes access to children's progress, assignments, evaluations and reports that allow parents to guide what their children do day to day."])},
  "our_prog_ally_for_you_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Educational Partner"])},
  "our_prog_ally_for_you_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is offered in different packages that adapt to the needs of every family."])},
  "our_prog_TPM_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® is your best bilingual partner to see your dreams for your children come true."])},
  "our_prog_TPM_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet TPM®"])},
  "about_HPM_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transform the Lives of Your Children"])},
  "about_HPM_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since 2003 TPM® has been providing our community with educational tools and a strong commitment to excellence in education. Our experience with the creation, production, and distribution of educational technology programs that drive learning is deeply rooted in our desire to educate 21st century learners. Our programs include courses from Preschool to High School Since 2003 TPM® has been providing our community with educational tools and a strong commitment to excellence in education. "])},
  "about_HPM_learn_more_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
  "about_HPM_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education is the most powerful weapon you can use to change the world"])},
  "about_HPM_auther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelson Mandela"])},
  "blog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® Blog"])},
  "blog_read_more_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read More"])},
  "testimonies_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our customers experiences are the most important asset"])},
  "testimonies_watch_more_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch more"])},
  "footer_our_program_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our program"])},
  "footer_our_program_versions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® Versions"])},
  "footer_our_program_career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Career"])},
  "footer_our_program_follow_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us"])},
  "footer_about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About TPM"])},
  "footer_about_mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission"])},
  "footer_about_FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "footer_about_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "footer_about_terms_of_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
  "footer_blog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
  "footer_testimonies_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimonies"])},
  "footer_testimonies_youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube"])},
  "footer_contact_us_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "footer_contact_us_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave us your data to be able to carry out a demonstration of our product."])},
  "footer_contact_us_address_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8635 Florence Ave. Suite 205"])},
  "footer_contact_us_address_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90241"])},
  "footer_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" TPM® All Rights Reserved."])},
  "AU_about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
  "AU_M_V": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM believes that educational technology works better to engage children’s learning because it makes concepts more interesting, relevant and fun. Since 2003 we develop experience to supporting families with educational technology, TPM can help your children reach their dreams of attending college."])},
  "AU_mission_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission"])},
  "AU_mission_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To help parents support their children in reaching their dreams of college, by creating and developing bilingual educational products that incorporate technology, fun and academic excellence."])},
  "AU_vision_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vision"])},
  "AU_vision_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be a leader in the development of excellent bilingual educational programs for families in the United States and Latin America"])},
  "AU_M_V_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transforming the lives of children in our communities"])},
  "AU_M_V_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission and Vision"])},
  "aboutHMP_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® since 2003 is an organization with experience and a strong commitment to excellence in education. Our experience with the creation, production, and distribution of educational technology programs that drive learning is deeply rooted in our desire to educate 21st century learners. Our programs include courses from Preschool to High School, and in both English/Spanish. We are commited to supporting your children's success."])},
  "program_info_b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our programs are NOT SOLD IN STORES, they are offered through personalized advisory. Our educational advisors offer personalized consulting through chats with parents where they evaluate the needs of their children and present to them programs and/or tools available within their home in order to prepare them for a bright future making it to College."])},
  "program_info_b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The dream is to grow up and obtain what was previously out of our reach, and part of that dream is that our children are able to finish College; not only does that give us a title, but offers us a possibility for a better life."])},
  "program_info_b3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The best valued jobs value knowledge and talent, which come before any other quality at the time of getting a job."])},
  "meet_tpm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet TPM®"])},
  "meet_tpm_txt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our TPM® software covers all content from Preschool to the 12th grade, providing instruction 24 hours a day, 365 days of the year. TPM is offered in different packages to adapt to the needs of every family."])},
  "meet_tpm_txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our software includes a section dedicated to parents that gives them easy access in viewing their children's progress, managing assignments and reviewing reports. This will then help parents control of what their children will study and learn in TPM each day."])},
  "meet_tpm_txt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our bilingual education programs are made so that the parents can be more involved in their children's education and growth, even with limited knowledge of the English language."])},
  "tpm_desktop_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® Desktop Version"])},
  "tpm_desktop_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our software installs locally on your desktop or laptop, where children will be able to access all of the topics to study."])},
  "tpm_desktop_tutor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutor"])},
  "tpm_desktop_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
  "tpm_d_student_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each student, as registered by the Tutor, will be able to log in using a username and password and access the content of their registered current grade. They will be able to advance gradually through each subject's content by approving respective tests and quizzes."])},
  "tpm_d_student_l1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login."])},
  "tpm_d_student_l2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesing a subject."])},
  "tpm_d_student_l3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson study."])},
  "tpm_d_student_l4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
  "tpm_d_student_l5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test and game time."])},
  "tpm_d_student_l6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson search."])},
  "tpm_d_student_l7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glossary"])},
  "tpm_d_student_l8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual library."])},
  "tpm_d_tutor_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Tutor will be able to manage and control content according to the grade that the student is registered to. Also they will be able to configure tests, quizes and resports, as well as checking study activities and student progress."])},
  "tpm_d_tutor_l1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited ammount of tutors and students."])},
  "tpm_d_tutor_l2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure student access, enable and disable content."])},
  "tpm_d_tutor_l3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check activity history, tests and evaluations."])},
  "tpm_d_tutor_l4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worksheets and answers."])},
  "tpm_d_tutor_l5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure tests and quizzes."])},
  "tpm_d_tutor_l6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® full installation."])},
  "tpm_d_tutor_l7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed reports via e-mail containing student activity and progress information."])},
  "tpm_web_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® Web Version"])},
  "tpm_web_txt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ONLINE EDUCATION CAN REINFORCE THE FAMILY UNIT and provide the individual attention that children need to thrive, but getting started can be a big change for parents"])},
  "tpm_web_txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our curriculum: Build for success. EDUCATION MUST HELP PREPARE STUDENTS for work, college and life. And on the basis of a good education, you will find a solid curriculum. Since 2003, we have developed, tested and improved the TPM® curriculum to ensure that it meets state and national standards, as well as those established by the major educational organizations. The most important thing is that our curriculum is designed to meet the diverse needs of our students."])},
  "tpm_web_txt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® Web offers a new approach for students who want more. Our unique combination of in-person and online learning is just one of many things that transform the typical school experience from ordinary to extraordinary."])},
  "tpm_web_b1_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilingual program"])},
  "tpm_web_b1_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilingual content available from grade 1 to grade 12."])},
  "tpm_web_b2_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didactic learning"])},
  "tpm_web_b2_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning with lessons and videos that reinforce what is learned at the end of the lessons."])},
  "tpm_web_b3_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual library"])},
  "tpm_web_b3_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have more than 8,000 books classified by categories."])},
  "tpm_web_b4_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games"])},
  "tpm_web_b4_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward games at the end of each test, avaiable in all grades"])},
  "tpm_web_b5_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom content"])},
  "tpm_web_b5_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content adapted to each school stage that the student studies."])},
  "tpm_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® is your best partner for your children's dreams to come true!"])},
  "view_demos_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo Videos"])},
  "view_demos_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our TPM® software covers all content from Preschool to the 12th grade, providing instruction 24 hours a day, 365 days of the year. TPM is offered in different packages to adapt to the needs of every family"])},
  "ebook_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-books"])},
  "ebook_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for your interest - Enjoy TPM"])},
  "select_request_demo_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your plan"])},
  "select_request_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose.."])},
  "select_request_demoP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preschool"])},
  "select_request_demoK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindergarten"])},
  "select_request_demo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st Grade"])},
  "select_request_demo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2nd Grade"])},
  "select_request_demo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3rd Grade"])},
  "select_request_demo4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4th Grade"])},
  "select_request_demo5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5th Grade"])},
  "demo_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request a demo"])},
  "demo_card_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card information (** Just for verify your identity)"])},
  "demo_fname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name*"])},
  "demo_flastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name*"])},
  "demo_fphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "demo_femail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email*"])},
  "demo_faddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address*"])},
  "demo_fstate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State*"])},
  "demo_fcity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City*"])},
  "demo_fzipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code*"])},
  "demo_fcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card number*"])},
  "demo_fmonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month*"])},
  "demo_fyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year*"])},
  "btn_demo_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request free trial"])},
  "demo_txt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To sign up for the TPM® free trial, you must have a valid credit card, and give to us your real information, that alow us to verify your identity."])},
  "demo_txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: You'll be enrolled in the free trial of TPM® program and have access to platform FREE Seven Days. For more information about TPM® Program, go to TPM® Terms & Conditions."])},
  "demo_txt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You won't be charged for your free trial. We doesn't save your credit card information."])},
  "demo_txt4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet 'The Perfect Classmate'; the best bilingual educational program on the market, designed to help your children get better in their studies."])},
  "demo_txt5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BILINGIAL EDUCATIONAL PROGRAM FROM PRESCHOOL TO HIGH SCHOOL, PREPARED IN ACCORDANCE TO COMMON CORE STANDARDS."])},
  "career_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Careers"])},
  "career_join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join our team!"])},
  "career_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The TPM team includes dedicated, caring, excited, motivated people. They have a passion for helping families get their children to college and to the opportunities that college can bring. The team works together wonderfully and we hire people with the same kind of passion, drive and dedication to help children reach their goal of college. See below for any openings. If we do not have any current openings but you would still like to share your resume with us, please send it to: "])},
  "career_sales_representative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales representative"])},
  "career_sales_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales Manager"])},
  "career_sm_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working with TPM® you have the benefit and freedom of being your own boss, working and achieving the goals that you set yourself."])},
  "career_sm_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be your own boss"])},
  "career_sm_offer_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer"])},
  "career_sm_offer_txt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost-free qualified appoinments"])},
  "career_sm_offer_txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% approval rate"])},
  "career_sm_offer_txt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit for every level"])},
  "career_sm_offer_txt4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only 3 levels of excelent commisions"])},
  "career_sm_requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DO YOU MEET THE REQUIREMENTS?"])},
  "career_requirements_txt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In TPM® we work only with sales professionals who:"])},
  "career_requirements_txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominate references."])},
  "career_requirements_txt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make successful presentations."])},
  "career_requirements_txt4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recognize success of others and learn from it."])},
  "career_requirements_txt5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listen to needs of their clients."])},
  "career_requirements_txt6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can handle objections and rejection."])},
  "career_requirements_txt7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilize technology in their favor."])},
  "career_requirements_txt8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Know how to close."])},
  "career_requirements_txt9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have experience."])},
  "team_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Team"])},
  "member_luccas_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Dept"])},
  "member_luccas_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My name is Luccas Medina, I am in charge of making the installations and customer service. I currently have 4 years of experience as a networking engineer. My hobbies generally consist of singing and playing basketball. In my spare time I enjoy playing video games and reading. About my literary tastes, my favorite books are: 11 Rings and Mamba Mentality."])},
  "member_luccas_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service - Installation Dept.Manager"])},
  "member_luccas_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
  "member_luccas_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8635 Florence Ave. S-205 Downey, CA 90240"])},
  "member_luccas_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_luccas_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_luccas_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_edgar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational representative"])},
  "member_edgar_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My name is Erick Lopez Rodriguez, I am an education advisor. I currently have 3 years as an SDA chruch teacher/counselor, 8 years as an education vendor. My hobbies generally consist of singing, playing guitar. Regarding my literary tastes, my favorite books are: The Bible, The Desired of All Centuries, The Conflict of All Centuries and 3 Little Women."])},
  "member_edgar_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chavez Services"])},
  "member_edgar_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono:"])},
  "member_edgar_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_edgar_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo eléctronico "])},
  "member_edgar_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web "])},
  "member_edgar_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_eduardo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational representative"])},
  "member_eduardo_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studia"])},
  "member_eduardo_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
  "member_eduardo_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(714)574 5340"])},
  "member_eduardo_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_eduardo_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_eduardo_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "member_eduardo_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_eduardo_text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(562)399 8088"])},
  "member_erick_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational Representative "])},
  "member_elizabeth_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My name is Erick Lopez Rodriguez, I am an education advisor. I currently have 3 years as an SDA chruch teacher/counselor, 8 years as an education vendor. My hobbies generally consist of singing, playing guitar. Regarding my literary tastes, my favorite books are: The Bible, The Desired of All Centuries, The Conflict of All Centuries and 3 Little Women."])},
  "member_elizabeth_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E Cruz Services"])},
  "member_elizabeth_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
  "member_elizabeth_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_elizabeth_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_elizabeth_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "member_elizabeth_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_elizabeth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational Adviser "])},
  "member_erick_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My name is Erick Lopez Rodriguez, I am an education advisor. I currently have 3 years as an SDA chruch teacher/counselor, 8 years as an education vendor. My hobbies generally consist of singing, playing guitar. Regarding my literary tastes, my favorite books are: The Bible, The Desired of All Centuries, The Conflict of All Centuries and 3 Little Women."])},
  "member_erick_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erick Education"])},
  "member_erick_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
  "member_erick_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_erick_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_erick_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "member_erick_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_gricelda_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational representative "])},
  "member_gricelda_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My name is Gricelda Lopez, I am an executive representative. I have 20 years of experience as a supervisor in the educational area, 5 years ago visiting families to guide them on how to help their children to one day make it to university. My hobbies generally consist of riding a bike on the beach, enjoying my family, cook what they like, walk my dogs. About my literary tastes, my favorite books are: Your erroneous zones, Think, the path of the wolf."])},
  "member_gricelda_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gricelda E Services"])},
  "member_gricelda_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
  "member_gricelda_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Downey, CA 90240"])},
  "member_gricelda_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_gricelda_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_gricelda_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_griselda_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational representative "])},
  "member_griselda_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My name is Gricelda Lopez, I am an executive representative. I have 20 years of experience as a supervisor in the educational area, 5 years ago visiting families to guide them on how to help their children to one day make it to university. My hobbies generally consist of riding a bike on the beach, enjoying my family, cook what they like, walk my dogs. About my literary tastes, my favorite books are: Your erroneous zones, Think, the path of the wolf."])},
  "member_griselda_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guerrero Services"])},
  "member_griselda_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
  "member_griselda_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Downey, CA 90240"])},
  "member_griselda_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_griselda_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_griselda_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_patricia_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational representative"])},
  "member_patricia_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My name is Patricia Linares, I am an educational representative. I have 15 years of experience supervising and coaching groups and 6 years as an educational consultant. My hobbies generally consist of gardening and shopping. In my free time I enjoy a good dinner and being able to walk along the coast. About my literary tastes, my favorite books are: The power of the metamorphosis by Salvador Carrion, novels by Isabel Allende among others."])},
  "member_patricia_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" P. Lindsay"])},
  "member_patricia_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
  "member_patricia_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_patricia_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_patricia_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_patricia_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "footer_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorized TPM Distributor"])},
  "request_contact_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "request_contact_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave us your data to be able to show you a demo of our product."])},
  "request_contact_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
  "request_thks_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A representative will contact you to attend your request!"])},
  "request_thks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you"])},
  "request_tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Again"])},
  "request_tryAgain_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We could not register your data, please try again or contact us via"])},
  "btn_sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])}
}