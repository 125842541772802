<template>
 <!-- Start HeaderInside -->
<HeaderInside/>
<!-- End HeaderInside -->
<div>
<main id="request-demo" class="content-inside-main">
                <div  class="section-title color-blue">
                    <h1>Your Demo issue</h1>
                
                </div>
                <div class="container">
                      <div class="row justify-content-center">
                     <div class="col-xl-8">

                    <div class="card-body position-relative mx-0">
                        <div class="container">
                         <p>Sorry, your request was declined</p>
                          <p>Please contact us to help you.</p>
                        </div>
                        </div>
                        </div>
                     <div class="col-xl-4 ">                              
                                  <img src="@/assets/img/tpm-web.png" alt="" class="boy-img content-center"/>
                        </div>
                      
                    </div>
                     
                </div>
           

        </main><!-- End #main -->

</div>
    <!-- Start Footer -->
    <Footer/>   
    <!-- End Footer -->
</template>
<script>
import HeaderInside from "@/components/common/HeaderInside.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  props: ["data"],
  data() {
    return {
      scrollPosition: null,
      screenSize: null,
    };
  },
  name: "DemoIssue",
  components: {
    HeaderInside,
    Footer,
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    getDimensions() {
      this.screenSize = document.documentElement.clientWidth;
    },
  },
  mounted() {
      this.screenSize = document.documentElement.clientWidth;
    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener("resize", this.getDimensions);
    this.$router.push('/demo-issue');
    
  },
};
</script>

<style>
@import "@/assets/css/style.css";
@import "@/assets/vendor/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendor/bootstrap-icons/bootstrap-icons.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}
#request-demo .section-title{
    padding: 2rem;
}
footer{
    position: relative;
    top: 10rem;
}


</style>