<template>
  <div></div>
  <header
    id="header"
    class="fixed-top"
    v-bind:class="{ change_color: scrollPosition > 100 }"
  >
    <div
      class="container-fluid"
      v-bind:class="{ 'blog-details-header': page != 'home' }"
    >
      <div
        class="row justify-content-center container"
        v-bind:class="{ h100: page != 'home' }"
      >
        <div class="col-lg-4">
          <a href="/"
            ><img
              class="logo-bg"
              src="@/assets/img/tpm-education-for-all.png"
              alt=""
          /></a>
        </div>
        <div
          class="
            col-lg-8
            d-flex
            align-items-lg-stretch
            justify-content-lg-between
          "
        >
          <Navbar
            class="navbar order-last order-lg-0"
            id="navbar"
            name="Logo Name"
            :navLinks="[
              {
                name: $t('nav_our_program'),
                link: '#our-program',
                dropdown: false,
              },
              {
                name: $t('nav_about_HPM'),
                link: '#about',
                dropdown: false,
              },
              {
                name: $t('nav_blog'),
                link: '#blog',
                dropdown: false,
              },
              {
                name: $t('nav_contact_us'),
                link: '#contact',
                dropdown: false,
              },
            ]"
          />
        </div>
      </div>
      <div
        class="row"
        v-bind:class="{ dnone: scrollPosition > 100 }"
        v-if="page == 'home'"
      >
        <div class="col-xl-10"></div>
        <!--<div class="col-xl-2">
          <ul class="list-inline social-imges navbar-social" id="navbar-social">
            <li><img src="@/assets/img/youtube.png" alt="youtube" /></li>
            <li><img src="@/assets/img/instagram.png" alt="instagram" /></li>
            <li><img src="@/assets/img/facebook.png" alt="facebook" /></li>
          </ul>
        </div>-->
      </div>
    </div>
  </header>
</template>
<script>
import Navbar from "@/components/common/Navbar.vue";

export default {
  props: ["data"],
  data() {
    return {
      scrollPosition: null,
      screenSize: null,
        page : null
    };
  },
  name: "HeaderInside",
  components: {
    Navbar,
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    getDimensions() {
      this.screenSize = document.documentElement.clientWidth;
    },
  },
  mounted() {
    this.screenSize = document.documentElement.clientWidth;
    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener("resize", this.getDimensions);
    var currentURL = (window.location.href);
    var urlSegment = currentURL.split("/");
    this.page = urlSegment[3];
  },
};
</script>