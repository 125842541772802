import {createRouter, createWebHashHistory } from 'vue-router';
import Home from '@/components/Home.vue';
import BlogDetail from '@/components/BlogDetail.vue';
import MeetTPM from '@/components/MeetTPM.vue';
import AboutUs from '@/components/AboutUs.vue';
import ViewDemos from '@/components/ViewDemos.vue';
import Ebook from '@/components/Ebook.vue';
import RequestDemo from '@/components/RequestDemo.vue';
import DemoApproved from '@/components/DemoApproved.vue';
import DemoIssue from '@/components/DemoIssue.vue';
import Careers from '@/components/Careers.vue';
import RequestContact from '@/components/RequestContact.vue';
import ThankYou from '@/components/ThankYou.vue';
import TryAgain from '@/components/TryAgain.vue';
import Team from '@/components/Team.vue';
import Member from '@/components/Member.vue';
import MemberEdgar from '@/components/MemberEdgar.vue';
import MemberEduardo from '@/components/MemberEduardo.vue';
import MemberElizabeth from '@/components/MemberElizabeth.vue';
import MemberErick from '@/components/MemberErick.vue';
import MemberGriselda from '@/components/MemberGriselda.vue';
import MemberGricelda from '@/components/MemberGricelda.vue';
import MemberPatricia from '@/components/MemberPatricia.vue';

const   history = createWebHashHistory ();

const router = createRouter({
    history,
    routes :[
        {
            path: '/',
            name: 'Home',
            component: Home,//shsould be imported 
           
          },
          {
            path: '/detail/:id',
            name: 'BlogDetail',
            component: BlogDetail,//shsould be imported 
           
          },
          {
            path: '/meet-tpm/',
            name: 'MeetTPM',
            component: MeetTPM,//shsould be imported 
           
          },
          {
            path: '/about-us/',
            name: 'AboutUs',
            component: AboutUs,//shsould be imported 
           
          },
          {
            path: '/view-demos/',
            name: 'ViewDemos',
            component: ViewDemos,//shsould be imported 
           
          },
          {
            path: '/ebook/',
            name: 'Ebook',
            component: Ebook,//shsould be imported 
           
          },
          {
            path: '/request-contact/',
            name: 'RequestContact',
            component: RequestContact,//shsould be imported 
           
          },
          {
            path: '/request-demo/',
            name: 'RequestDemo',
            component: RequestDemo,//shsould be imported 
           
          },
          {
            path: '/demo-approved/',
            name: 'DemoApproved',
            component: DemoApproved,//shsould be imported 
           
          },
          {
            path: '/demo-issue/',
            name: 'DemoIssue',
            component: DemoIssue,//shsould be imported 
           
          },
          {
            path: '/careers/',
            name: 'Careers',
            component: Careers,//shsould be imported 
           
          },
          {
            path: '/thank-you/',
            name: 'ThankYou',
            component: ThankYou,//shsould be imported 
           
          },
          {
            path: '/try-again/',
            name: 'TryAgain',
            component: TryAgain,//shsould be imported 
           
          },
          {
            path: '/team/',
            name: 'Team',
            component: Team,//shsould be imported 
           
          },

          {
            path: '/member',
            name: 'Member',
            component: Member,//shsould be imported 
           
          },
          {
            path: '/memberedgar/',
            name: 'MemberEdgar',
            component: MemberEdgar,//shsould be imported 
           
          },

          {
            path: '/membereduardo/',
            name: 'MemberEduardo',
            component: MemberEduardo,//shsould be imported 
           
          },

          {
            path: '/memberelizabeth/',
            name: 'MemberElizabeth',
            component: MemberElizabeth,//shsould be imported 
           
          },

          {
            path: '/membererick/',
            name: 'MemberErick',
            component: MemberErick,//shsould be imported 
           
          },

          {
            path: '/membergriselda/',
            name: 'MemberGriselda',
            component: MemberGriselda,//shsould be imported 
           
          },
          {
            path: '/membergricelda/',
            name: 'MemberGricelda',
            component: MemberGricelda,//shsould be imported 
           
          },

          {
            path: '/memberpatricia/',
            name: 'MemberPatricia',
            component: MemberPatricia,//shsould be imported 
           
          },
    ],
    scrollBehavior (to, from, savedPosition) {
      window.scrollTo(0,0);
      if (to.hash) {
        return {
          el: to.hash,
        }
      }
    }
  })
  export default router;