<template>
 <!-- Start HeaderInside -->
<HeaderInside/>
<!-- End HeaderInside -->
<div>
<main id="meet-tpm" class="content-inside-main">
                <div  class="section-title color-blue">
                    <h1>{{ $t("meet_tpm_title") }}</h1>
                </div>
                <div class="container">
                     <p>{{ $t("meet_tpm_txt1") }}</p>
                     <p>{{ $t("meet_tpm_txt2") }}</p>
                     <p>{{ $t("meet_tpm_txt3") }}</p>
                     <br>
                   
                </div>
            <!-- ======= Desktop Version Section ======= -->
            <section id="desktop-version" class="orange-bakground-wave">
                <div class="container">

                    <div class="row justify-content-center">
                        <h2 class="edi-text-img">{{ $t("tpm_desktop_title") }}</h2>
                         <div class="col-xl-4">
                                
                                <p>{{ $t("tpm_desktop_txt") }}</p>
                                <img src="@/assets/img/tpm-desktop.png" alt="" class="boy-img"/>
                        </div>
                        <div class="col-xl-8">
                            
                            <p></p>

                                <div id="desktop-info">
                                    <div class="container">
                                        
                                        <ul class="nav nav-tabs nav-justified">
                                        <li class="nav-item">
                                            <a class="nav-link" @click.prevent="setActive('studentD')" :class="{ active: isActive('studentD') }" href="#studentD">{{ $t("tpm_desktop_student") }}</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" @click.prevent="setActive('tutorD')" :class="{ active: isActive('tutorD') }" href="#tutorD">{{ $t("tpm_desktop_tutor") }}</a>
                                        </li>
                                       
                                        </ul>
                                        <div class="tab-content py-3" id="myTabContent">
                                            <div class="tab-pane fade" :class="{ 'active show': isActive('studentD') }" id="studentD">
                                            <p>{{ $t("tpm_d_student_txt") }}</p>
                                            <ul>
                                                <li>{{ $t("tpm_d_student_l1") }}</li>
                                                <li>{{ $t("tpm_d_student_l2") }}</li>
                                                <li>{{ $t("tpm_d_student_l3") }}</li>
                                                <li>{{ $t("tpm_d_student_l4") }}</li>
                                                <li>{{ $t("tpm_d_student_l5") }}</li>
                                                <li>{{ $t("tpm_d_student_l6") }}</li>
                                                <li>{{ $t("tpm_d_student_l7") }}</li>
                                                <li>{{ $t("tpm_d_student_l8") }}</li>
                                               
                                            </ul>
                                        </div>
                                        <div class="tab-pane fade" :class="{ 'active show': isActive('tutorD') }" id="tutorD">
                                            <p>{{ $t("tpm_d_tutor_txt") }}</p>
                                            <ul>
                                                <li>{{ $t("tpm_d_tutor_l1") }}</li>
                                                <li>{{ $t("tpm_d_tutor_l2") }}</li>
                                                <li>{{ $t("tpm_d_tutor_l3") }}</li>
                                                <li>{{ $t("tpm_d_tutor_l4") }}</li>
                                                <li>{{ $t("tpm_d_tutor_l5") }}</li>                                                                                                                                                                                                
                                                <li>{{ $t("tpm_d_tutor_l6") }}</li>
                                                <li>{{ $t("tpm_d_tutor_l7") }}</li>                                                                                                
                                               
                                            </ul>
                                        </div>
                                     
                                        </div>
                                    </div>
                                    </div>
                        </div>

                       
                    </div>
                </div>

            </section><!-- End Desktop Version Section -->

            <!-- ======= Web Version Section ======= -->
            <section id="web-version" >
       
                <div class="container">

                    <div class="row justify-content-center">

                        
                        <div class="col-xl-8">
                             <div class="row">
                                  <h2 class="edi-text-img">{{ $t("tpm_web_title") }}</h2>
                                  <p>{{ $t("tpm_web_txt1") }}</p>
                                  <p>{{ $t("tpm_web_txt2") }}</p>
                                  <p>{{ $t("tpm_web_txt3") }}</p>
                                  <p></p>
                                   <p></p>
                            <div class="col-xl-6 col-md-6">
                                <div class="row icon-box">
                                     <div class="col-xl-3 icon yellow"><img src="@/assets/img/united-states.png" alt="" /></div>
                                    <div class="col-xl-9">
                                        <h4>{{ $t("tpm_web_b1_tit") }}</h4>
                                        <p>{{ $t("tpm_web_b1_txt") }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-6 col-md-6">
                                <div class="row icon-box">
                                    <div class="col-xl-3 icon green"><img src="@/assets/img/copywriting.png" alt="" /></div>
                                    <div class="col-xl-9">
                                       <h4>{{ $t("tpm_web_b2_tit") }}</h4>
                                       <p>{{ $t("tpm_web_b2_txt") }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-6 col-md-6">
                                <div class="row icon-box">
                                    <div class="col-xl-3 icon blue"><img src="@/assets/img/tpm-web.png" alt="" /></div>
                                    <div class="col-xl-9">
                                       <h4>{{ $t("tpm_web_b3_tit") }}</h4>
                                        <p>{{ $t("tpm_web_b3_txt") }}</p>
                                    </div>
                                </div>
                            </div>

                             <div class="col-xl-6 col-md-6">
                                <div class="row icon-box">
                                    <div class="col-xl-3 icon blue"><img src="@/assets/img/boy.png" alt="" /></div>
                                    <div class="col-xl-9">
                                        <h4>{{ $t("tpm_web_b4_tit") }}</h4>
                                       <p>{{ $t("tpm_web_b4_txt") }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-6 col-md-6">
                                <div class="row icon-box">
                                    <div class="col-xl-3 icon sky"><img src="@/assets/img/tpm-desktop.png" alt="" /></div>
                                    <div class="col-xl-9">
                                        <h4>{{ $t("tpm_web_b5_tit") }}</h4>
                                        <p>{{ $t("tpm_web_b5_txt") }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                     <div class="col-xl-4 content-center">
                                <img src="@/assets/img/tpm-web.png" alt="" class="boy-img"/>  
                                
                                 <div class="d-flex">
               
                                    <button class="btn btn-primary tpm-button">
                                    <router-link :to="{ path: '/view-demos/' }">
                                        {{ $t("banner_free_trial_label") }}
                                    </router-link>
                                    </button>
                                </div>

                        </div>
                      
                    </div>
                </div>

            </section><!-- End Web Version Section -->

              

        </main><!-- End #main -->

</div>
    <!-- Start Footer -->
    <Footer/>   
    <!-- End Footer -->
</template>
<script>
import HeaderInside from "@/components/common/HeaderInside.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  props: ["data"],
  data() {
    return {
    activeItem: 'studentD', 
      scrollPosition: null,
      screenSize: null,
    };
  },
  name: "MeetTPM",
  components: {
    HeaderInside,
    Footer,
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    getDimensions() {
      this.screenSize = document.documentElement.clientWidth;
    },
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
  },
  mounted() {
      this.screenSize = document.documentElement.clientWidth;
    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener("resize", this.getDimensions);
    this.$router.push('/meet-tpm');
  },
};
</script>

<style>
@import "@/assets/css/style.css";
@import "@/assets/vendor/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendor/bootstrap-icons/bootstrap-icons.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}
#meet-tpm .section-title{
    padding: 2rem;
}
footer{
    position: relative;
}
#demo{
    margin-bottom: 5rem;
}
#desktop-version,#web-version{
    padding-top: 4rem;;
}

</style>