<template>
 <!-- Start HeaderInside -->
<HeaderInside/>
<!-- End HeaderInside -->

<div>
<main id="request-demo" class="content-inside-main">
                <div  class="section-title color-blue">
                    <h1>{{ $t("request_contact_tit") }}</h1>
                     <p style="text-align:center">{{ $t("request_contact_txt") }}</p>

                </div>
                <div class="container">
                      <div class="row justify-content-center">
                     <div class="col-xl-8">

                    <div class="card-body position-relative mx-0">
                        <div class="container">
                        <form id="formulariolanding" action="" @submit.prevent="submit">
                            <div class="controls">
                             
                                  
                                <div class="row row-1">
                                    <div class="col-md-6">
                                        <div class="form-group"> <label for="form_nombre">{{ $t("demo_fname") }}</label> <input id="FNAME" type="text" name="FNAME" v-model="FNAME" class="form-control" required="required" pattern="[A-Za-zñÑáéíóúÁÉÍÓÚ\s]+">
                                     
                                        </div>
                                    </div>
                                     <div class="col-md-6">
                                        <div class="form-group"> <label for="form_apellido">{{ $t("demo_flastname") }}</label> <input id="LNAME" type="text" name="LNAME" v-model="LNAME" class="form-control" required="required" pattern="[A-Za-zñÑáéíóúÁÉÍÓÚ\s]+">
                                     
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="form_telefono">{{ $t("demo_fphone") }}</label>
                                            <input id="phone" type="text" name="phone" v-model="phone" class="form-control" required="required" pattern="[0-9]+" minlength="7" maxlength="12">
                                        
                                        </div>
                                  
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="form_correo">{{ $t("demo_femail") }}</label>
                                            <input id="form_correo" type="email" name="correo" v-model="correo" class="form-control" required="required">
                               
                                        </div>
                                    </div> 
                                    <div class="col-md-12"> 
                                       <div class="form-group"> <label for="form_address">{{ $t("demo_faddress") }}</label> <input id="BADDR1" type="text" name="BADDR1" v-model="BADDR1" class="form-control" required="required" pattern="[A-Za-zñÑáéíóúÁÉÍÓÚ\s]+">
                                  
                                        </div> 
                                    </div>                                                                    
                               
                                 <div class="col-md-4">
                                    <div class="form-group "> <label for="form_state">{{ $t("demo_fstate") }}</label> 
                                      <select id="BSTATE"  name="BSTATE" v-model="BSTATE"  class="form-control" @change="changeCountry($event)"  required="required">
                                        <option v-for="state in Jstates" :key="state.code" :value="state.code">
                                          {{ state.state }}
                                        </option>
                                      </select>                                    
                                    </div>
                                </div>
                                 <div class="col-md-4">
                                    <div class="form-group "> <label for="form_city">{{ $t("demo_fcity") }}</label>
                                     <select id="BCITY"  name="BCITY" v-model="BCITY"  class="form-control"  required="required">
                                        <option v-for="city in cities" :key="city" :value="city">
                                          {{ city }}
                                        </option>
                                      </select>
                      
                                    </div>

                                 </div>
                                 <div class="col-md-4">
                                    <div class="form-group  col-m-4"> <label for="form_zipcode">{{ $t("demo_fzipcode") }}</label> <input id="BZIP1" type="text" name="BZIP1" v-model="BZIP1" class="form-control" required="required" >
                                    
                                    </div>
                                </div>
                                  
                                </div>                               
                                 <div class="col-md-12"> 
                                    <input id="form_utm" type="hidden" name="canallanding" data-validate="false" value="Web">
                                    <!--<input type="submit" name="Submit" value="Request" />-->
                                    <button id="btn_submit" class="btn btn-danger" type="submit" @click="submit">{{ $t("request_contact_btn") }} </button>
                                    </div>
                                
                            </div>
                            </form>
                            <div id="respuestalanding"></div>
                        </div>
                        </div>
                        </div>
                     <div class="col-xl-4 ">
                               
                                  <img src="@/assets/img/tpm-web.png" alt="" class="boy-img content-center"/>
                        </div>
                      
                    </div>
                     
                </div>
           

        </main><!-- End #main -->

</div>
    <!-- Start Footer -->
    <Footer/>   
    <!-- End Footer -->
</template>

<script>
import HeaderInside from "@/components/common/HeaderInside.vue";
import Footer from "@/components/common/Footer.vue";
import jsonStates from '@/assets/data/states.json';
import jsonCities from '@/assets/data/US_States_and_Cities.json';
export default {
  props: ["data"],
  el: '#Planlist',
  data() {
    return {
    selectedState: '', 
    cities: [],
    Jstates: jsonStates,
    Jcities: jsonCities,
      scrollPosition: null,
      screenSize: null,
      errors: [],
      FNAME: null,
      LNAME: null,
      phone: null,
      correo: null,
      BADDR1: null,
      BCOUNTRY: null,
      BCITY: null,
      BSTATE: null,
      BZIP1: null,
      BCcard:null,
      BccMonth:null,
      BccYear:null
    };
  },
  name: "RequestContact",
  components: {
    HeaderInside,
    Footer,
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    getDimensions() {
      this.screenSize = document.documentElement.clientWidth;
    },
    getSource(name){
        var results = new RegExp('[\?&]' + name + '=([^]*)').exec(window.location.href);
        if (results==null){
           return null;
        }
        else{
          return results[1] || 0;
        }
    },
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    checkForm: function (e) {
      if (this.name && this.age) {
        return true;
      }

      this.errors = [];

      if (!this.name) {
        this.errors.push('Name required.');
      }
      if (!this.age) {
        this.errors.push('Age required.');
      }

      e.preventDefault();
    },
    changeCountry (event) {
      var list = JSON.parse(JSON.stringify(this.Jcities));
      this.selectedCountry = event.target.options[event.target.options.selectedIndex].text
      Object.entries(list).forEach(([key, value]) => {
        if(this.selectedCountry == key){
          this.cities = value;
        }
        
      })  
            
    },    
    submit(){
      var vm = this;
      var go=false;
      var utm_source= this.getSource('utm_source');
      var canallanding='Web_TPM';
      if (utm_source!=null){
        var canallanding = utm_source.split('&')[0];
      }
      $("#form_utm").val(canallanding);
      $('#btn_submit').prop('disabled', true);
      var mydataV = {
          first_name: vm.FNAME,
          last_name: vm.LNAME,
          phone_number: vm.phone,
          email: vm.correo,
          address: vm.BADDR1,
          city: vm.BCITY,
          state: vm.BSTATE,
          zip_code: vm.BZIP1,         
          utm_source: canallanding
        };
        console.log(mydataV); 
        $.ajax({
          method: 'POST',
          url:'https://mytpmschool.com/data/tpm.php',
          data:mydataV,
          success: function(response){
            console.log(response);
             if(response=='1'){
               $(location).attr("href", "https://mytpmschool.com/#/thank-you");
             }else{
               $(location).attr("href", "https://mytpmschool.com/#/try-again");
             }
          },
          error: function(response){
            console.log(response);
          },
          complete:function(response){
             console.log("completo");
             
          }
        });
    }
  },
  mounted() {
    this.screenSize = document.documentElement.clientWidth;
    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener("resize", this.getDimensions);
    this.$router.push('/request-contact');
    $('#BccYear').datepicker({
        format: " yyyy", // Notice the Extra space at the beginning
        viewMode: "years",
        minViewMode: "years"
      });
      $('#BccMonth').datepicker({
        format: " mm", // Notice the Extra space at the beginning
        viewMode: "months",
        minViewMode: "months"
      });
        
  },
};
</script>

<style>
@import "@/assets/css/style.css";
@import "@/assets/vendor/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendor/bootstrap-icons/bootstrap-icons.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}
#request-demo .section-title{
    padding: 2rem;
}
footer{
    position: relative;
    top: 10rem;
}


</style>