<template>
  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="container" id="contact">
      <div class="row">
        <div class="col-xl-2">
          <img
            src="@/assets/img/tpm-education-for-all.png"
            alt="footer-logo"
            class="footer-logo"
          />
        </div>
        <div class="col-xl-2 col-md-6">
          <h4>{{ $t("footer_our_program_title") }}</h4>
          <ul class="list-inline">
            <li>
              <a href="/#/meet-tpm">{{ $t("footer_our_program_versions") }}</a>
            </li>
            <!--<li>
              <a href="">{{ $t("footer_our_program_career") }}</a>
            </li>-->
           <!-- <li>
              {{ $t("footer_our_program_follow_us") }}
            </li>-->
          </ul>
          <!--<ul class="list-inline social-imges">
            <li><img src="@/assets/img/youtube.png" alt="youtube" /></li>
            <li><img src="@/assets/img/instagram.png" alt="instagram" /></li>
            <li><img src="@/assets/img/facebook.png" alt="facebook" /></li>
          </ul>-->
        </div>
        <div class="col-xl-2 col-md-6">
          <h4>{{ $t("footer_about_title") }}</h4>
          <ul class="list-inline">
            <li><a href="/#/about-us">{{ $t("footer_about_mission") }}</a></li>
            <!--<li><a href="">{{ $t("footer_about_FAQ") }}</a></li>-->
            <li><a href="/data/privacy_terms.pdf" target="_blank">{{ $t("footer_about_privacy_policy") }}</a></li>
            <li><a href="/data/Terms_of_Service.pdf" target="_blank">{{ $t("footer_about_terms_of_use") }}</a></li>
          </ul>
        </div>
        <div class="col-xl-1 col-md-6">
          <a href="/#/#blog"><h4>{{ $t("footer_blog_title") }}</h4></a>
        </div>
        <div class="col-xl-2 col-md-6">
          <h4>{{ $t("footer_testimonies_title") }}</h4>
          <ul class="list-inline">
            <li><a href="https://www.youtube.com/channel/UCrOzn9jUeoqQ39f9u8Pa22g" target="_blank">{{ $t("footer_testimonies_youtube") }}</a></li>
          </ul>
        </div>
        <div class="col-xl-3">
          <h4>{{ $t("footer_contact_us_title") }}</h4>
          <ul class="list-inline contact-footer">
             
            

            <li class="d-flex">
              <p class="footer_icon">
                <img src="@/assets/img/Asset26.png" alt="location" />
              </p>
              <p><a href="https://g.page/HomeAndMediaProducts?share" targe="_blank">{{ $t("footer_contact_us_address_1") }}<br />{{ $t("footer_contact_us_address_2") }}</a></p>
              
            </li>
            <li class="d-flex">
              <p class="footer_icon">
                <img src="@/assets/img/Asset24.png" alt="telephone" />
              </p>
              <p>
                <a href="tel:+5623998088" target="_blank">(562)399-8088</a>
              </p>
            </li>
            <li class="d-flex">
              <p class="footer_icon">
                <img src="@/assets/img/tpm-support.png" alt="support" />
              </p>
              <p>
                <a href="mailto:help@mytpmschool.com" target="_blank">help@mytpmschool.com</a>
              </p>
              
            </li>

           
            <!--<li class="d-flex">
              <p class="footer_icon web_p">
                <img class="" src="@/assets/img/Asset25.png" alt="website" />
              </p>
              <p><a class="web_icon" href="">www.myhmp.org</a></p>
            </li>-->
          </ul>
        </div>
      </div>

      <div class="row copyright-footer">
        <div class="col-xl-6">
          <p>&copy;{{new Date().getFullYear()}}{{ $t("footer_rights") }}</p>
        </div>
        <div class="col-xl-6 text-right">
          <img src="@/assets/img/Asset13.png" alt="madeinusa" />
        </div>
      </div>
    </div>
  </footer>
  <!-- End  -->
</template>