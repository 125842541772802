<template>
 <!-- Start HeaderInside -->
<HeaderInside/>
<!-- End HeaderInside -->
<div>
       <!-- ======= Hero Section ======= -->
    <section id="hero">
      <div class="banner-color">
        <div class="container">
          <div class="row hero-top">
            <h1>{{ $t("career_title") }}</h1>
            <div class="col-xl-6">
              
              <p>{{ $t("career_txt") }}</p>
              <p></p>
              <p></p>
              <p></p>
              <h3>{{ $t("career_join") }}</h3>
               <div class="d-flex">
               
                <div class="row">
              <div class="col-xl-6 col-md-6">
                <div class="row icon-box">
                  <div class="col-xl-3 icon blue">
                    <img src="@/assets/img/tpm-sales-representative.png" alt="" />
                  </div>
                  <div class="col-xl-9">
                    <p></p>
                    <p></p>
                    <h4>
                      {{ $t("career_sales_representative") }}
                    </h4>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-md-6">
                <div class="row icon-box">
                  <div class="col-xl-3 icon blue">
                    <img src="@/assets/img/copywriting.png" alt="" />
                  </div>
                  <div class="col-xl-9">
                    <h4>
                     {{ $t("career_sales_manager") }}
                    </h4>
                  </div>
                </div>
              </div>

       

            </div>
              </div>
           
             
            </div>
            <div class="col-xl-6">
              <img src="@/assets/img/tpm-careers.png" alt="" class="banner-img" />
              
              <!--</div>-->
            </div>
          </div>
        </div>
      </div>

    </section>
    <!-- End Hero -->
<main id="careers-tpm" >
                 <section id="sales-manager" >
       
                <div class="container">

                    <div class="row justify-content-center">

                        
                        <div class="col-xl-8">
                             <div class="row">
                                  <h2 class="edi-text-img" style="text-align:left" >{{ $t("career_sales_manager") }}</h2>
                                   <p></p>
                                  <h3>{{ $t("career_sm_tit") }}</h3>
                                 
                                  <p>{{ $t("career_sm_txt") }}</p>
                                   
                                    <h3 class="edi-text-img" style="text-align:left">{{ $t("career_sm_offer_tit") }}</h3>
                                    <p></p>
                                    <p></p>
                            <div class="col-xl-6 col-md-6">
                                <div class="row icon-box">
                                     <div class="col-xl-3 icon yellow"><img src="@/assets/img/tpm-agenda.png" alt="" /></div>
                                    <div class="col-xl-9">
                                        <h4>{{ $t("career_sm_offer_txt1") }}</h4>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-6 col-md-6">
                                <div class="row icon-box">
                                    <div class="col-xl-3 icon green"><img src="@/assets/img/tpm-aprobado.png" alt="" /></div>
                                    <div class="col-xl-9">
                                       <h4>{{ $t("career_sm_offer_txt2") }}</h4>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-6 col-md-6">
                                <div class="row icon-box">
                                    <div class="col-xl-3 icon blue"><img src="@/assets/img/tpm-credito.png" alt="" /></div>
                                    <div class="col-xl-9">
                                       <h4>{{ $t("career_sm_offer_txt3") }}</h4>
                                    </div>
                                </div>
                            </div>

                             <div class="col-xl-6 col-md-6">
                                <div class="row icon-box">
                                   <div class="col-xl-3 icon blue"><img src="@/assets/img/podium.png" alt="" /></div>
                                    <div class="col-xl-9">
                                        <h4>{{ $t("career_sm_offer_txt4") }}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                     <div class="col-xl-4 content-center">
                                <img src="@/assets/img/tpm-manager.png" alt="" class="boy-img"/>                               
                        </div>
                      
                    </div>
                </div>

            </section><!-- End sales_manager Section -->
            <!-- ======= sales_representative Section ======= -->
            <section id="sales_representative" class="orange-bakground-wave">
              
                <div class="container">

                    <div class="row justify-content-center">
                      <hr>
                      <hr>
                        <h2 class="edi-text-img" style="text-align:left">{{ $t("career_sales_representative") }}</h2>
                         <div class="col-xl-4">
                                <p></p>
                                <p></p>
                                <h3>{{ $t("career_sm_requirements") }}</h3>
                                <img src="@/assets/img/tpm-sales-representative.png" alt="" class="boy-img"/>

                        </div>
                        <div class="col-xl-8">
                            
                            <p></p>

                                <div id="desktop-info">
                                    <div class="container">
                                        
                                         <h2>{{ $t("career_requirements_txt1") }}</h2>
                                        <div class="tab-content py-3" id="myTabContent">
                                            
                                          
                                            <ul>
                                                <li>{{ $t("career_requirements_txt2") }}</li>
                                                <li>{{ $t("career_requirements_txt3") }}</li>
                                                <li>{{ $t("career_requirements_txt4") }}</li>
                                                <li>{{ $t("career_requirements_txt5") }}</li>
                                                <li>{{ $t("career_requirements_txt6") }}</li>
                                                <li>{{ $t("career_requirements_txt7") }}</li>
                                                <li>{{ $t("career_requirements_txt8") }}</li>
                                                <li>{{ $t("career_requirements_txt9") }}</li>
                                               
                                            </ul>
                                        
                                        
                                     
                                        </div>
                                    </div>
                                    </div>
                        </div>

                       
                    </div>
                </div>

            </section>
           

        

        </main><!-- End #main -->

</div>
    <!-- Start Footer -->
    <Footer/>   
    <!-- End Footer -->
</template>
<script>
import HeaderInside from "@/components/common/HeaderInside.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  props: ["data"],
  data() {
    return {
    activeItem: 'studentD', 
      scrollPosition: null,
      screenSize: null,
    };
  },
  name: "Careers",
  components: {
    HeaderInside,
    Footer,
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    getDimensions() {
      this.screenSize = document.documentElement.clientWidth;
    },
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
  },
  mounted() {
      this.screenSize = document.documentElement.clientWidth;
    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener("resize", this.getDimensions);
    this.$router.push('/careers');
  },
};
</script>

<style>
@import "@/assets/css/style.css";
@import "@/assets/vendor/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendor/bootstrap-icons/bootstrap-icons.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}
#meet-tpm .section-title{
    padding: 2rem;
}
footer{
    position: relative;
}
#demo{
    margin-bottom: 5rem;
}
#desktop-version,#web-version{
    padding-top: 4rem;;
}

</style>