<template>
  <!-- Start Header -->
  <Header />
  <!-- End Header -->
  <div>
    <!-- ======= Hero Section ======= -->
    <section id="hero">
      <div class="banner-color">
        <div class="container">
          <div class="row hero-top">
            <div class="col-xl-6">
              <h1>{{ $t("banner_title") }}</h1>
              <p>{{ $t("banner_desc") }}</p>
              <div class="d-flex">
               
                <button class="btn btn-primary tpm-button">
                   <router-link :to="{ path: '/view-demos/' }">
                    {{ $t("banner_free_trial_label") }}
                  </router-link>
                </button>
              </div>
              
              <a href="https://www.bbb.org/us/ca/downey/profile/computer-software-developers/home-media-products-llc-1216-100107486" target="_blank"><img src="@/assets/img/logo_BBA.png" alt="" class="logo-bba" /></a>
              
            </div>


            <div class="col-xl-6">
              <img src="@/assets/img/Asset16.png" alt="" class="banner-img" />
              <div class="contact-us-home">
                <div class="contact-list">
                  <a href="https://wa.me/15623998088?text=I'm%20interested%20in%20TPM%20product" target="_blank"><img
                    src="@/assets/img/whatsapp.png"
                    class="wup-logo"
                    alt=""
                  /></a>
                  <a href="https://wa.me/15623998088?text=I'm%20interested%20in%20TPM%20product" target="_blank">
                    <div class="home-us">
                      <h6>{{ $t("banner_contact_us") }}</h6>
                      <h5>(562)399-8088</h5>
                    </div>
                  </a>
                </div>
                <div class="std-contact">
                  <img src="@/assets/img/Asset4.png" class="std-img" alt="" />
                </div>
              </div>
              <!--</div>-->
            </div>
          </div>
        </div>
      </div>

      <div id="our-program" class="container">
        <!-- Our program -->
        <div class="section-title">
          <h2>{{ $t("our_prog_title") }}</h2>
        </div>

        <div class="row content our-program">
          <div class="col-lg-8 align-items-stretch">
            <div class="row">
              <div class="col-xl-6 col-md-6">
                <div class="row icon-box">
                  <div class="col-xl-3 icon green">
                    <img src="@/assets/img/copywriting.png" alt="" />
                  </div>
                  <div class="col-xl-9">
                    <h4>
                      {{ $t("our_prog_content_title") }}
                    </h4>
                    <p>{{ $t("our_prog_content_desc") }}</p>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-md-6">
                <div class="row icon-box">
                  <div class="col-xl-3 icon yellow">
                    <img src="@/assets/img/united-states.png" alt="" />
                  </div>
                  <div class="col-xl-9">
                    <h4>
                     {{ $t("our_prog_bilingual_title") }}
                    </h4>
                    <p>{{ $t("our_prog_bilingual_desc") }}</p>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-md-6">
                <div class="row icon-box">
                  <div class="col-xl-3 icon blue">
                    <img src="@/assets/img/ux.png" alt="" />
                  </div>
                  <div class="col-xl-9">
                    <h4>
                      {{ $t("our_prog_software_title") }}
                    </h4>
                    <p>{{ $t("our_prog_software_desc") }}</p>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-md-6">
                <div class="row icon-box">
                  <div class="col-xl-3 icon sky">
                    <img src="@/assets/img/boy.png" alt="" />
                  </div>
                  <div class="col-xl-9">
                    <h4>
                      {{ $t("our_prog_ally_for_you_title") }}
                    </h4>
                    <p>{{ $t("our_prog_ally_for_you_desc") }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 align-items-stretch mt-4">
            <div class="meet-versions">
              <div class="red-bg-img program_img">
                <img src="@/assets/img/Asset17.png" alt="" />
              </div>
              <h2>{{ $t("our_prog_TPM_title") }}</h2>
                <button class="btn btn-info tpm-button">
                   <router-link :to="{ path: '/meet-tpm/' }">
                    {{ $t("our_prog_TPM_button") }}
                  </router-link>
                </button>

            </div>
          </div>
        </div>

        <!-- End Our program -->
      </div>
    </section>
    <!-- End Hero -->

    <main id="main">
      <!-- ======= About Us Section ======= -->
      <section id="about"  ref="about" class="about">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-6">
              <h1>{{ $t("about_HPM_title") }}</h1>
              <p>{{ $t("about_HPM_desc") }}</p>

              <button class="btn btn-danger tpm-button">
                   <router-link :to="{ path: '/about-us/' }">
                    {{ $t("about_HPM_learn_more_button") }}
                  </router-link>
                </button>
            </div>

            <div class="col-xl-6">
              <div class="about-text-bg">
                <img
                  src="@/assets/img/Asset8.png"
                  class="quotes"
                  alt="left-quotes"
                />
                <h3 class="edi-text-img">{{ $t("about_HPM_quote") }}</h3>
                <img
                  src="@/assets/img/Asset9.png"
                  class="quotes"
                  alt="right-quotes"
                />
                <p>{{ $t("about_HPM_auther") }}</p>
                <img src="@/assets/img/Asset6.png" alt="" class="boy-img" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End About Us Section -->

      <!-- ======= Blog Section ======= -->
      <section id="blog" class="blog">
        <div class="container">
          <div class="section-title">
            <h2>{{ $t("blog_title") }}</h2>
          </div>

          <div class="row" v-if="$i18n.locale === 'es'">
            <div
              class="col-xl-4 blog-cover"
              v-for="show in spanishBlogs"
              v-bind:key="show.sys.id"
            >
              <div class="blog-content">
                <img :src="show.previewMainImg.url" alt="" />
                <h3>{{ show.title }}</h3>
                <button class="btn-danger tpm-button">
                  <router-link :to="{ path: '/detail/' + show.sys.id }">
                    {{ $t("blog_read_more_btn") }}
                  </router-link>
                </button>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div
              class="col-xl-4 blog-cover"
              v-for="show in englishBlogs"
              v-bind:key="show.sys.id"
            >
              <div class="blog-content">
                <img :src="show.banner.url" alt="Banner Image" />
                <h3>{{ show.title }}</h3>
                <button class="btn-danger tpm-button">
                  <router-link :to="{ path: '/detail/' + show.sys.id }">
                    {{ $t("blog_read_more_btn") }}
                  </router-link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Blog Section -->

      <!-- ======= Testimonies Section ======= 
      <section id="testimonies" class="testimonies">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 text-center text-lg-start">
              <img
                src="@/assets/img/youtube.png"
                alt="youtube"
                class="youtube-img"
              />
              <h1>{{ $t("testimonies_title") }}</h1>
              <button class="btn btn-danger tpm-button">
                <a href="https://www.youtube.com/channel/UCrOzn9jUeoqQ39f9u8Pa22g" target="_blank">{{ $t("testimonies_watch_more_btn") }}</a>
              </button>
            </div>

            <div class="col-lg-6 video-img text-center">
              <div class="youtube-img-hover">
                <img src="@/assets/img/Asset21.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      End Testimonies Section -->
    </main>
    <!-- End #main -->
  </div>
  <!-- Start Footer -->
  <Footer />
  <!-- End Footer -->
</template>
<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  props: ["data"],
  data() {
    return {
      englishBlogs: [],
      spanishBlogs: [],
      scrollPosition: null,
      screenSize: null,
    };
  },
  name: "Home",
  components: {
    Header,
    Footer,
  },
  async created() {
    const shows = await this.getShows();
    this.englishBlogs = shows.filter(show => { return show.english ?  show :  null});
    this.spanishBlogs = shows.filter(show => { return !show.english ?  show :  null});
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    getDimensions() {
      this.screenSize = document.documentElement.clientWidth;
    },
    getShows: async () => {
      const query = `{
        blogCollection {
            items {
              sys {
                  id
              }
              title
                previewMainImg {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
    
              banner {
                title
                description
                contentType
                fileName
                size
                url
                width
                height
              }
              english
            }
          }
        }`;
      const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/55hdexlgp2jl`;
      const fetchOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer CjoPXzOgC0q7G5loxBgEzXQ2k5nbh_mgduAM5uTJZC8`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }),
      };

      try {
        const response = await fetch(fetchUrl, fetchOptions).then((response) =>
          response.json()
        );
        return response.data.blogCollection.items;
      } catch (error) {
        throw new Error("Could not receive the data from Contentful!");
      }
    },
  },
  mounted() {
    this.screenSize = document.documentElement.clientWidth;
    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener("resize", this.getDimensions);
  },
};
</script>
<style>
@import "@/assets/css/style.css";
@import "@/assets/vendor/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendor/bootstrap-icons/bootstrap-icons.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}

.logo{
  height: 50%;
  width: 50%;
}
</style>