<template>
 <!-- Start HeaderInside -->
<HeaderInside/>
<!-- End HeaderInside -->
<div>
<main id="view-demos" class="content-inside-main">
            <!-- ======= Mission and vision Section ======= -->
            <section id="general-demo" class="">

                <div class="container">

                    <div class="row justify-content-center">
                        <div class="col-xl-6">
                            <h1 class="color-blue">{{ $t("view_demos_title") }}</h1>
                            <p>{{ $t("view_demos_txt") }}</p>
                            <h3> {{ $t("demo_tit") }}</h3>
                            <button class="btn btn-info tpm-button">
                              <router-link :to="{ path: '/request-contact/' }">
                                {{ $t("btn_sign_up") }}
                              </router-link>
                            </button>
                             
                        </div>

                        <div class="col-xl-6">
                            <div class="about-text-bg">
                                <img src="@/assets/img/boy.png" alt="" class="boy-img"/>
                            </div>
                        </div>
                    </div>
                </div>

            </section><!-- Mission and vision Section -->

          <!--  aboutHMP Section -->
            <section id="aboutHMP" class="orange-bakground-wave">
                <div class="container">

                    <div class="row justify-content-center">
                       
      
                        <div class="col-xl-4">
                           <iframe width="100%" height="315" src="https://www.youtube.com/embed/O5fJmGUED1g" title="TPM Home" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                         <div class="col-xl-4">
                           <iframe width="100%" height="315" src="https://www.youtube.com/embed/mWzsSVaRugA" title="TPM worksheets" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                         <div class="col-xl-4">
                          <iframe width="100%" height="315" src="https://www.youtube.com/embed/xubn6dDCaIE" title="TPM Glossary" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                         <div class="col-xl-4">
                          <iframe width="100%" height="315" src="https://www.youtube.com/embed/wPk1fIq_Awk" title="TPM Game Zone" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div class="col-xl-4">
                          <iframe width="100%" height="315" src="https://www.youtube.com/embed/odO4TeotfqI" title="TPM e-library" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                       
                    </div>
                    <div class="content-center">
                      <p>{{ $t("demo_txt4") }}</p>
                      <h3>{{ $t("demo_tit") }}</h3>
                      <button class="btn btn-info tpm-button">
                              <router-link :to="{ path: '/request-contact/' }">
                                {{ $t("btn_sign_up") }}
                              </router-link>
                            </button>   
                    </div>
                </div>

            </section><!-- End aboutHMP Section -->

            <!-- ======= program info Section ======= -->
            <section id="program-info">
       
                <div class="container">

                    <div class="row justify-content-center">
                        <div class="row">                         
                                <p>{{ $t("demo_txt5") }}</p>                           
                                <p>{{ $t("tpm_web_txt2") }}</p>
                                <button class="btn btn-info tpm-button">
                                  <router-link :to="{ path: '/request-contact/' }">
                                    {{ $t("btn_sign_up") }}
                                  </router-link>
                              </button> 
                        </div>
                      
                    </div>
                </div>

            </section> <!--program info Section -->

              

        </main><!-- End #main -->

</div>
    <!-- Start Footer -->
    <Footer/>   
    <!-- End Footer -->
</template>
<script>
import HeaderInside from "@/components/common/HeaderInside.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  props: ["data"],
  data() {
    return {
      scrollPosition: null,
      screenSize: null,
    };
  },
  name: "ViewDemos",
  components: {
    HeaderInside,
    Footer,
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    getDimensions() {
      this.screenSize = document.documentElement.clientWidth;
    },
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
  },
  mounted() {
      this.screenSize = document.documentElement.clientWidth;
    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener("resize", this.getDimensions);
    this.$router.push('/view-demos');
  },
};
</script>

<style>
@import "@/assets/css/style.css";
@import "@/assets/vendor/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendor/bootstrap-icons/bootstrap-icons.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}
#mision-vision{
    padding: 4rem 0;
}
footer{
    position: relative;
}
#program-info{
    margin-bottom: 5rem;
}
#view-demos,#program-info{
    padding-top: 4rem;;
}

</style>