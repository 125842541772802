<template>
 <!-- Start HeaderInside -->
<HeaderInside/>
<!-- End HeaderInside -->
<div>
<main id="about-us" class="content-inside-main">
            <!-- ======= Mission and vision Section ======= -->
            <section id="mision-vision" class="">

                <div class="container">

                    <div class="row justify-content-center">
                        <div class="col-xl-6">
                            <h1 class="color-blue">{{ $t("AU_about_title") }}</h1>
                            <p>{{ $t("AU_M_V") }}</p>
                            <h2 class="subtitle content-left">{{ $t("AU_mission_title") }}</h2>
                            <p>{{ $t("AU_mission_txt") }}</p>
                            <h2 class="subtitle content-left">{{ $t("AU_vision_title") }}</h2>
                            <p>{{ $t("AU_vision_txt") }}</p>
                          
                        </div>

                        <div class="col-xl-6">
                            <div class="about-text-bg">
                                <img src="@/assets/img/Asset8.png" class="quotes" alt="left-quotes" />
                                <h3 class="edi-text-img">{{ $t("AU_M_V_quote") }}</h3>
                                <img src="@/assets/img/Asset9.png" class="quotes" alt="right-quotes" />
                                <p>{{ $t("AU_M_V_txt") }}</p>
                                <img src="@/assets/img/boy.png" alt="" class="boy-img"/>

                            </div>
                        </div>
                    </div>
                </div>

            </section><!-- Mission and vision Section -->

          <!--  aboutHMP Section -->
            <section id="aboutHMP" class="orange-bakground-wave">
                <div class="container">

                    <div class="row justify-content-center">
                       
                         <div class="col-xl-4">
                                <img src="@/assets/img/tpm-desktop.png" alt="" class="boy-img"/>
                        </div>
                        <div class="col-xl-8">
                           <h2 class="subtitle content-left">Home & Media Products, LLC.</h2>
                            <p>{{ $t("aboutHMP_txt") }}</p>
                        </div>

                       
                    </div>
                </div>

            </section><!-- End aboutHMP Section -->

            <!-- ======= program info Section ======= -->
            <section id="program-info">
       
                <div class="container">

                    <div class="row justify-content-center">                        
                        <div class="col-xl-8">
                             <div class="row">                         
                              <p>{{ $t("program_info_b1") }}</p>
                              <p>{{ $t("program_info_b2") }}</p>
                              <p>{{ $t("program_info_b3") }}</p>                         
                            </div>
                        </div>
                     <div class="col-xl-4 content-center">
                                <img src="@/assets/img/tpm-web.png" alt="" class="boy-img"/>
                        </div>
                      
                    </div>
                </div>

            </section><!--  program info Section -->

              

        </main><!-- End #main -->

</div>
    <!-- Start Footer -->
    <Footer/>   
    <!-- End Footer -->
</template>
<script>
import HeaderInside from "@/components/common/HeaderInside.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  props: ["data"],
  data() {
    return {
    activeItem: 'tutorD', 
      scrollPosition: null,
      screenSize: null,
    };
  },
  name: "AboutUs",
  components: {
    HeaderInside,
    Footer,
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    getDimensions() {
      this.screenSize = document.documentElement.clientWidth;
    },
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
  },
  mounted() {
      this.screenSize = document.documentElement.clientWidth;
    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener("resize", this.getDimensions);
    this.$router.push('/about-us');
  },
};
</script>

<style>
@import "@/assets/css/style.css";
@import "@/assets/vendor/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendor/bootstrap-icons/bootstrap-icons.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}
#mision-vision{
    padding: 4rem 0;
}
footer{
    position: relative;
}
#program-info{
    margin-bottom: 5rem;
}
#aboutHMP,#program-info{
    padding-top: 4rem;;
}

</style>