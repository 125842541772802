<template>
 <!-- Start HeaderInside -->
<HeaderInside/>
<!-- End HeaderInside -->
<div>
<main id="ebook" class="content-inside-main">
            <!-- ======= Mission and vision Section ======= -->
            <section id="general-demo" class="">

                <div class="container">

                    <div class="row justify-content-center">
                        <div class="col-12 text-align-center">
                            <h1 class="color-blue">{{ $t("ebook_title") }}</h1>                           
                        </div>                     
                    </div>
                </div>

            </section><!-- Mission and vision Section -->

          <!--  aboutHMP Section -->
            <section id="aboutHMP" class="orange-bakground-wave">
                <div class="container">

                    <div class="row justify-content-center">
                       
                       <div class="col-md-3">
                           <a href="https://mytpmschool.com/docs/Elementary_School_E-book.pdf" target="_blank"><img
                              src="@/assets/img/ES-ebook.png"
                              alt="ebook Elementary School"
                            /></a>
                        </div>
                        <div class="col-md-3">
                           <a href="https://mytpmschool.com/docs/Elementary_School_E-book.pdf" target="_blank"><img
                              src="@/assets/img/ES-ebook.png"
                              alt="ebook Elementary School"
                            /></a>
                        </div>
                         <div class="col-md-3">
                            <a href="https://mytpmschool.com/docs/Middle_School_E-book.pdf" target="_blank"><img
                              src="@/assets/img/MS-ebook.png"
                              alt="ebook Middle School"
                            /></a>
                        </div>
                         <div class="col-md-3">
                           <a href="https://mytpmschool.com/docs/High_School_E-book.pdf" target="_blank"><img
                              src="@/assets/img/HS-ebook.png"
                              alt="ebook High School"
                            /></a>
                        </div>
                       
                       
                       
                    </div>
                    <div class="content-center">
                      <br />
                      <br />
                      <p>Meet "The Perfect Classmate"; the best bilingual educational program on the market, designed to help your children get better in their studies.</p>
                      <h3>Request a demo</h3>
                      <button class="btn btn-info tpm-button">
                              <router-link :to="{ path: '/request-contact/' }">
                                {{ $t("btn_sign_up") }}
                              </router-link>
                            </button>   
                    </div>
                </div>

            </section><!-- End aboutHMP Section -->

        
              

        </main><!-- End #main -->

</div>
    <!-- Start Footer -->
    <Footer/>   
    <!-- End Footer -->
</template>
<script>
import HeaderInside from "@/components/common/HeaderInside.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  props: ["data"],
  data() {
    return {
      scrollPosition: null,
      screenSize: null,
    };
  },
  name: "Ebook",
  components: {
    HeaderInside,
    Footer,
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    getDimensions() {
      this.screenSize = document.documentElement.clientWidth;
    },
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
  },
  mounted() {
      this.screenSize = document.documentElement.clientWidth;
    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener("resize", this.getDimensions);
    this.$router.push('/ebook');
  },
};
</script>

<style>
@import "@/assets/css/style.css";
@import "@/assets/vendor/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendor/bootstrap-icons/bootstrap-icons.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}
#mision-vision{
    padding: 4rem 0;
}
footer{
    position: relative;
}
#program-info{
    margin-bottom: 5rem;
}
#view-demos,#program-info{
    padding-top: 4rem;;
}

</style>