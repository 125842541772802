<template>
 <!-- Start Header -->
<HeaderInside/>
 <!-- End Header -->
 <!-- Start #main -->
  <main id="main">
    <!-- ======= About Us Section ======= -->
    <section class="blog-details-content">
      <div class="container">
        <div class="row justify-content-center"  v-for="show in shows"  v-bind:key="show.sys.id">
         
          <div class="col-xl-12 " v-if="show.sys.id == blogId">
            <h1 class="content-center">{{show.title}}</h1>
              <img  :src=show.banner.url   alt="blog-img" />
               <div class="row justify-content-center"  v-for="content in show.content.json.content"  v-bind:key="content.value">
            <p>
              {{content.content[0].value}}
            </p>
               </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End About Us Section -->
  </main>
  <!-- End #main -->
   <!-- Start Footer -->
   <Footer/>   
    <!-- End Footer -->
</template>
<script>
import HeaderInside from "@/components/common/HeaderInside.vue";
import Footer from "@/components/common/Footer.vue";
import { useRoute } from 'vue-router';

export default {
 
  name: "BlogDetail",
  props: ["data"],
  data() {
    return {
     shows:[],
      scrollPosition: null,
      screenSize: null,
      blogId : null,
    
    };
  },
  components: {
    HeaderInside,
    Footer,
  },
    async created() {
   this.shows = await this.getShows();
   
 },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    getDimensions() {
      this.screenSize = document.documentElement.clientWidth;
    },
    getShows: async () => {
     const query = `{
  blogCollection {
    items {
      sys {
        id
      }
    title
    content{
      json
    }
    banner {
      title
      description
      contentType
      fileName
      size
      url
      width
      height
    }
    }
  }
}`;
     const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/55hdexlgp2jl`;
     const fetchOptions = {
       method: "POST",
       headers: {
         Authorization: `Bearer CjoPXzOgC0q7G5loxBgEzXQ2k5nbh_mgduAM5uTJZC8`,
         "Content-Type": "application/json"
       },
       body: JSON.stringify({ query })
     };

     try {
       const response = await fetch(fetchUrl, fetchOptions).then(response =>
         response.json()
       );
       return response.data.blogCollection.items;
     } catch (error) {
       throw new Error("Could not receive the data from Contentful!");
     }
   }
  },
  mounted() {
    const route = useRoute()
    this.blogId = route.params.id;
    this.screenSize = document.documentElement.clientWidth;
    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener("resize", this.getDimensions);
  },
};
</script>
<style>
@import "@/assets/css/style.css";
@import "@/assets/vendor/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendor/bootstrap-icons/bootstrap-icons.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}
</style>