<template>
 <!-- Start HeaderInside -->
<HeaderInside/>
<!-- End HeaderInside -->
<div class="portada">
<main id="request-demo" class="content-inside-main">
                <div  class="section-title color-blue">
                  

                     <div class="container">
          <div class="row hero-top">
             <div class="col-xl-6" style="background: rgba(255,255,255,0.7);">
             <hr>
                  <h1>{{ $t("member_erick_title") }}</h1>
                    <p>{{ $t("member_erick_text1") }}</p>
                    <p style="color:black">{{ $t("member_erick_text2") }}</p>
                 
                      <p> <a href="tel:+3238305908" target="_blank">(323)830-5908</a> </p>
                      <p style="color:black">{{ $t("member_eduardo_text4") }}</p>
                     
                      <p>
                        <a href="mailto:helpee@mytpmschool.com" target="_blank">helpee@mytpmschool.com</a>
                      </p>
                     
                      <p>
                        <a href="https://ee.mytpmschool.com" target="_blank">ee.mytpmschool.com</a>
                      </p>
                    
                      <p> <a href="tel:+5623998088" target="_blank">(562)399-8088</a> </p>
              
             
              <p></p>
              <p></p>
              <p></p>
             
               <div class="d-flex">
               
                <div class="row">
              

              

       

            </div>
              </div>
           
             
            </div>
            <div class="col-xl-6">
              <img src="@/assets/img/tpm-ericklopez.png"  width="500" height="600" alt="" />
              
              <!--</div>-->
            </div>
          </div>
        </div>
                    

                
                </div>

                 
                
           

        </main><!-- End #main -->

</div>
    <!-- Start Footer -->
    <Footer/>   
    <!-- End Footer -->
</template>
<script>
import HeaderInside from "@/components/common/HeaderInside.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  props: ["data"],
  data() {
    return {
      scrollPosition: null,
      screenSize: null,
    };
  },
  name: "MemberErick",
  components: {
    HeaderInside,
    Footer,
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    getDimensions() {
      this.screenSize = document.documentElement.clientWidth;
    },
  },
  mounted() {
      this.screenSize = document.documentElement.clientWidth;
    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener("resize", this.getDimensions);
    this.$router.push('/membererick');
    
  },
};
</script>

<style>
@import "@/assets/css/style.css";
@import "@/assets/vendor/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendor/bootstrap-icons/bootstrap-icons.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}
#request-demo .section-title{
    padding: 2rem;
}
footer{
    position: relative;
    top: 10rem;
}

.portada{
   background: url("@/assets/img/tpm-fondo.png") no-repeat fixed center;
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
   height: 100%;
   width: 100% ;
   text-align: center;
   
 
}

</style>