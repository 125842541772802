<template>
 <!-- Start HeaderInside -->
<HeaderInside/>
<!-- End HeaderInside -->

<body>
<div class="portada">
       <!-- ======= Hero Section ======= -->
    <section id="hero">
      
        <div class="container" >
           
           <div class="row justify-content-center">

                       
        
                        <div class="col-xl-8">
                           
                             <div class="row hero-top">
                               <h1>{{ $t("team_title") }}</h1>
                               <p></p>
                               <p></p>
                               <p></p>
                            <div class="col-xl-12 col-md-2">
                                <div class="row icon-box">
                                     <div class="col-xl-3 "><img src="@/assets/img/tpm-edgarchavez.png" alt="" width="100%" height="100%"/></div>
                                     <div class="col-xl-3 "><img src="@/assets/img/tpm-eduardoporras.png" alt="" width="100%" height="100%"/></div>
                                      <div class="col-xl-3 "><img src="@/assets/img/tpm-elizabethcruz.png" alt="" width="100%" height="100%"/></div>
                                      <div class="col-xl-3 "><img src="@/assets/img/tpm-patricialinares.png" alt="" width="100%" height="100%"/></div>
                                </div>

                                 <div class="row icon-box">
                                     <div class="col-xl-3 "><img src="@/assets/img/tpm-ericklopez.png" alt="" width="100%" height="100%"/></div>
                                     <div class="col-xl-3 "><img src="@/assets/img/tpm-griceldalopez.png" alt="" width="100%" height="100%"/></div>
                                      <div class="col-xl-3 "><img src="@/assets/img/tpm-luccasmedina.png" alt="" width="100%" height="100%"/></div>
                                      <div class="col-xl-3 "><img src="@/assets/img/tpm-griseldaguerrero.png" alt="" width="100%" height="100%"/></div>
                                </div>
                            </div>
                            
                             </div>
          
                        </div>
           </div>

        </div>
      

    </section>
    <!-- End Hero -->
<main id="team-tpm" >
                 <section id="team" >
       
                

            </section><!-- End -->

        </main><!-- End #main -->

</div>
</body>
    <!-- Start Footer -->
    <Footer/>   
    <!-- End Footer -->
</template>
<script>
import HeaderInside from "@/components/common/HeaderInside.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  props: ["data"],
  data() {
    return {
    activeItem: 'studentD', 
      scrollPosition: null,
      screenSize: null,
    };
  },
  name: "Team",
  components: {
    HeaderInside,
    Footer,
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    getDimensions() {
      this.screenSize = document.documentElement.clientWidth;
    },
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
  },
  mounted() {
      this.screenSize = document.documentElement.clientWidth;
    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener("resize", this.getDimensions);
    this.$router.push('/team');
  },
};
</script>

<style>
@import "@/assets/css/style.css";
@import "@/assets/vendor/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendor/bootstrap-icons/bootstrap-icons.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  
  overflow-x: hidden;
  width:100%;

}
#meet-tpm .section-title{
    padding: 2rem;
}
footer{
    position: relative;
}
#demo{
    margin-bottom: 5rem;
}
#desktop-version,#web-version{
    padding-top: 4rem;;
}
.portada{
   background: url("@/assets/img/tpm-fondo.png") no-repeat fixed center;
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
   height: 100%;
   width: 100% ;
   text-align: center;
   
 
}

.team{
  padding: 25px;
}
</style>