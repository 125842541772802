<template>
  <nav
    id="navbar"
    class="navbar"
    v-bind:class="{ navbar_mobile: isAddMobileNavClass }"
  >
    <!-- <ul class="nav-links" v-if="page != 'detail'"> -->
       <ul class="nav-links">
     
      <li v-for="list in navLinks" :key="list.key"> 
        <router-link 
          :to="{name: 'Home', hash: list.link}">
          {{list.name}}
        </router-link>   
       
      </li>
      <li>
         <router-link :to="{ path: '/view-demos/' }">
           {{$t("nav_view_demo") }}
          </router-link>

      </li>
      <li class="tpm_login">
        <a href="https://www.mytpmschool.org/" target="_blank">{{$t("nav_tpm") }}</a>
      </li>
      <li v-if=!isAddMobileNavClass>
        <a
          class="nav-link scrollto spain-img" 
          href="#"
          @click.prevent="$i18n.locale = 'es'"
          v-if="$i18n.locale === 'en'"
          ><img src="@/assets/img/spain.png" height=57px top = 3px
        /></a>
        <a
          class="nav-link scrollto spain-img"
          href="#"
          @click.prevent="$i18n.locale = 'en'"
          v-if="$i18n.locale === 'es'"
          ><img src="@/assets/img/united-states.png" height=57px top = 3px
        /></a>
      </li>
      <li v-else>
        <a
          class="nav-link"
          href="#"
          @click.prevent="$i18n.locale = 'es'"
          v-if="$i18n.locale === 'en'"
          >{{ $t("nav_locale_es")}}</a>
        <a
          class="nav-link"
          href="#"
          @click.prevent="$i18n.locale = 'en'"
          v-if="$i18n.locale === 'es'"
          >{{ $t("nav_locale_en")}}</a>
      </li>
    </ul>
    <i class="bi bi-list mobile-nav-toggle" v-on:click="openMobileNav()"></i>
  </nav>
</template>

<script>
export default {
  name: "",
  props: ["name", "logoImg", "navLinks", "data"],
  data() {
    return {
      isAddMobileNavClass: false,
      page: null
    };
  },

 

  methods: {
    openMobileNav() {
      this.isAddMobileNavClass = !this.isAddMobileNavClass;
    },
    removeMobileNav() {
      this.isAddMobileNavClass = false;
    },
    scrollMeTo(refName) {
       const element = document.getElementById(refName);

      if (element) {
         element.scrollIntoView({
           block: "center",
           behavior: "smooth",
         });
      }
    },
  },
  mounted() {
    //if (window.innerWidth < 768) this.isAddMobileNavClass = true;
    //else this.isAddMobileNavClass = false;
     var currentURL = (window.location.href);
    var urlSegment = currentURL.split("/");
    this.page = urlSegment[3];
  },
  
  
};

</script>
