export default {
  "nav_our_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro programa"])},
  "nav_about_HPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de TPM"])},
  "nav_blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
  "nav_testimonies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimonios"])},
  "nav_contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctanos"])},
  "nav_view_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver Demos"])},
  "nav_locale_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglés"])},
  "nav_locale_es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
  "nav_tpm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® Login"])},
  "banner_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planificar, aprender y crecer juntos..."])},
  "banner_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® será su compañero de clase en casa, te ayudaremos a superar las tareas escolares diarias y juntos construiremos un futuro brillante para tus hijos."])},
  "banner_free_trial_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicita un Demo"])},
  "banner_contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctanos"])},
  "our_prog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro programa"])},
  "our_prog_content_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenido"])},
  "our_prog_content_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El software cubre todo el contenido desde preescolar hasta el grado 12, lo que le permite enseñar a sus alumnos las 24 horas del día, los 365 días del año."])},
  "our_prog_bilingual_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilingüe"])},
  "our_prog_bilingual_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se hacen programas para que los padres puedan involucrarse aún sin conocimiento del idioma inglés."])},
  "our_prog_software_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software"])},
  "our_prog_software_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incluye acceso y medición del progreso de tus hijos, gestión de tareas, asignaciones e informes que permiten un control absoluto de lo que hace tu hijo día a día."])},
  "our_prog_ally_for_you_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un aliado para ti"])},
  "our_prog_ally_for_you_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ofrece en diferentes paquetes que se adaptan a las necesidades de cada familia."])},
  "our_prog_TPM_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡TPM es tu mejor aliado para que los sueños de tus hijos se hagan realidad!"])},
  "our_prog_TPM_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conozca las versiones de TPM®"])},
  "about_HPM_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformar la vida de los niños y jóvenes"])},
  "about_HPM_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® es una marca que ha ganado experiencia desde el 2003. Tenemos un fuerte compromiso con la excelencia en la educación. Nuestra experiencia con la creación, producción y distribución de programas de tecnología educativa que impulsan el aprendizaje está profundamente arraigada en nuestro deseo de educar a los estudiantes. Nuestros programas incluyen cursos desde preescolar hasta secundaria, y en inglés y español. Estamos comprometidos a ayudar a los padres a lograr el éxito de tus hijos. Nuestros programas TPM® NO SE VENDEN EN TIENDAS, se ofrecen a través de asesoría personalizada."])},
  "about_HPM_learn_more_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende más"])},
  "about_HPM_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La educación es el arma más poderosa que puedes usar para cambiar el mundo"])},
  "about_HPM_auther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelson Mandela"])},
  "blog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog de TPM®"])},
  "blog_read_more_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer más"])},
  "testimonies_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las experiencias de nuestros clientes son el activo más importante"])},
  "testimonies_watch_more_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más"])},
  "footer_our_program_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro programa"])},
  "footer_our_program_versions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versiones de TPM®"])},
  "footer_our_program_career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrera"])},
  "footer_our_program_follow_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Síganos"])},
  "footer_about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de TPM"])},
  "footer_about_mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misión"])},
  "footer_about_FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas más frecuentes"])},
  "footer_about_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "footer_about_terms_of_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
  "footer_blog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
  "footer_testimonies_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimonios"])},
  "footer_testimonies_youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube"])},
  "footer_contact_us_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctanos"])},
  "footer_contact_us_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dejanos tus datos para poder realizar una demostración de nuestro producto."])},
  "footer_contact_us_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8635 Florence Ave. Suite 205<br />Downey, CA 90241"])},
  "footer_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" TPM, LLC. Todos los derechos reservados."])},
  "AU_about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de nosotros"])},
  "AU_M_V": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM cree que la tecnología educativa funciona mejor para involucrar el aprendizaje de los niños porque hace que los conceptos sean más interesantes, relevantes y divertidos. Estamos en el mercado desde el 2003, contamos con experiencia en el apoyo a familias hispanas con tecnología educativa, TPM puede ayudar a sus hijos a alcanzar sus sueños de asistir a la universidad."])},
  "AU_mission_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mision"])},
  "AU_mission_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para ayudar a los padres de habla hispana a apoyar a sus hijos a alcanzar sus sueños universitarios, creando y desarrollando productos educativos bilingües que incorporen tecnología, diversión y excelencia académica."])},
  "AU_vision_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vision"])},
  "AU_vision_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ser un líder en el desarrollo de excelentes programas educativos bilingües para familias en los Estados Unidos y América Latina."])},
  "AU_M_V_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformando la vida de los niños en nuestras comunidades"])},
  "AU_M_V_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misión y Visión"])},
  "aboutHMP_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® es una marca que ha ganado experiencia desde el 2003.  Tenemos un fuerte compromiso con la excelencia en la educación. Nuestra experiencia con la creación, producción y distribución de programas de tecnología educativa que impulsan el aprendizaje está profundamente arraigada en nuestro deseo de educar a los estudiantes. Nuestros programas incluyen cursos desde preescolar hasta secundaria, y en inglés y español."])},
  "program_info_b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestros programas NO SE VENDEN EN TIENDAS, se ofrecen a través de asesorías personalizadas. Nuestros Representantes ofrecen charlas a los padres de familia en donde evaluamos las necesidades de sus hijos y les presentamos los diferentes programas y/o herramientas disponibles para sus estudiantes y así prepararlos para un brillante futuro llegando a la Universidad"])},
  "program_info_b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El sueño americano es crecer y obtener todo lo que nunca antes estuvo a nuestro alcance y parte de ese sueño es que nuestros hijos terminen la Universidad; ya que no sólo nos da un título, sino que les ofrece la oportunidad de una vida mejor."])},
  "program_info_b3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los empleos mejores calificados valoran el conocimiento y el talento que vienen adelante de cualquier otra cualidad en el momento de obtener un empleo."])},
  "meet_tpm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué es TPM®?"])},
  "meet_tpm_txt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro programa TPM® cubre desde el Preschool hasta el Grado 12, para convertirse en maestro de su estudiante 24 horas al día 365 días al año y lo ofrecemos en diferentes paquetes que se adecúan a las necesidades de cada hogar."])},
  "meet_tpm_txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro programa incluye una sección dedicada exclusivamente a los padres de familia para que puedan en una forma fácil visualizar todos los avances, tareas, asignaciones y muchos más reportes para el control absoluto de lo que su estudiante hace día a día."])},
  "meet_tpm_txt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestros programas educativos bilingües son para que los padres de familia se involucren en la educación y crecimiento educativo de sus hijos y de esta manera puedan ayudarlos en su educación aún sin conocimiento del idioma Inglés."])},
  "tpm_desktop_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® Versión Escritorio"])},
  "tpm_desktop_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta modalidad funciona en base a instalación local de DVDs en la computadora donde tu hijo podrá estudiar el grado en el que se encuentra, o el grado que quieras que repase."])},
  "tpm_desktop_tutor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutor"])},
  "tpm_desktop_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estudiante"])},
  "tpm_d_student_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el usuario Estudiante, una vez que lo haya registrado el tutor, podrá iniciar sesión con su nombre de usuario y contraseña al contenido del grado el cuál fue registrado. Podrá avanzar gradualmente en los contenidos de cada materia conforme apruebe los test y quizzes."])},
  "tpm_d_student_l1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de sesión."])},
  "tpm_d_student_l2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar a un materia."])},
  "tpm_d_student_l3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estudio de una lección"])},
  "tpm_d_student_l4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
  "tpm_d_student_l5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test y tiempo de juego."])},
  "tpm_d_student_l6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Búsqueda de lecciones."])},
  "tpm_d_student_l7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glosario"])},
  "tpm_d_student_l8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteca virtual."])},
  "tpm_d_tutor_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En este tipo de usuario, el Tutor podrá activar y desactivar contenido según el grado que este registrado el estudiante, o bien, darle acceso total al mismo. Además podrá configurar tests y los quiz, el envío de reportes y checar las actividades de estudio así como su progreso de los estudiantes registrados."])},
  "tpm_d_tutor_l1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro ilimitados de tutores y estudiantes."])},
  "tpm_d_tutor_l2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar acceso total al estudiante, o habilitar contenido seleccionado."])},
  "tpm_d_tutor_l3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar el historial de actividad, test y evaluaciones."])},
  "tpm_d_tutor_l4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hojas de tareas y respuestas."])},
  "tpm_d_tutor_l5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de test."])},
  "tpm_d_tutor_l6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalación completa de TPM®"])},
  "tpm_d_tutor_l7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reportes detallados vía correo de cada estudiante."])},
  "tpm_web_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® Versión Web"])},
  "tpm_web_txt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LA EDUCACIÓN EN LÍNEA PUEDE REFORZAR LA UNIDAD FAMILIAR y brindar la atención individual que los niños necesitan para prosperar, pero el comenzar puede ser un gran cambio para los padres."])},
  "tpm_web_txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro currículum: Construido para el éxito. LA EDUCACIÓN DEBE AYUDAR A PREPARAR A LOS ESTUDIANTES para el trabajo, la universidad y la vida. Y en la base de una buena educación, se encuentra un plan de estudios sólido. Desde el 2003, hemos desarrollado, probado y mejorado el plan de estudios de TPM® para garantizar que cumpla con los estándares estatales y nacionales, así como con los establecidos por las principales organizaciones educativas. Lo más importante es que nuestro plan de estudios está diseñado para satisfacer las diversas necesidades de nuestros estudiantes."])},
  "tpm_web_txt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® Web ofrece un enfoque nuevo para los estudiantes que desean más. Nuestra combinación única de aprendizaje en persona y en línea es solo una de las muchas cosas que transforman la experiencia escolar típica de ordinaria a extraordinaria."])},
  "tpm_web_b1_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programa bilingüe"])},
  "tpm_web_b1_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenido bilingüe disponible desde grado 1 hasta grado 12."])},
  "tpm_web_b2_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprendizaje didáctico"])},
  "tpm_web_b2_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprendizaje con lecciones y videos que refuerzan lo aprendido al término de las lecciones."])},
  "tpm_web_b3_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteca virtual"])},
  "tpm_web_b3_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contamos con más de 8,000 libros clasificados por categorías."])},
  "tpm_web_b4_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juegos"])},
  "tpm_web_b4_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juegos de recompensa al término de cada test, disponible en todos los grados."])},
  "tpm_web_b5_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenido personalizado"])},
  "tpm_web_b5_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenido adaptado a cada etapa escolar que cursa el estudiante."])},
  "tpm_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡TPM es su mejor amigo para poder hacer que ese sueño se convierta en una realidad!"])},
  "view_demos_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver Demos"])},
  "view_demos_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro programa TPM® cubre desde el Preschool hasta el Grado 12, para convertirse en maestro de su estudiante 24 horas al día 365 días al año y lo ofrecemos en diferentes paquetes que se adecúan a las necesidades de cada hogar."])},
  "ebook_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-books"])},
  "ebook_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias por tu interés - Disfruta TPM!"])},
  "select_request_demo_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona tu plan"])},
  "select_request_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione.."])},
  "select_request_demoP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PreKinder"])},
  "select_request_demoK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinder"])},
  "select_request_demo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1er Grado"])},
  "select_request_demo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2do Grado"])},
  "select_request_demo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3er Grado"])},
  "select_request_demo4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4to Grado"])},
  "select_request_demo5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5to Grado"])},
  "demo_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicita tu demo de TPM®"])},
  "demo_card_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de tarjeta de crédito (** Solo se usará para verificar tu identidad)"])},
  "demo_fname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre*"])},
  "demo_flastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido*"])},
  "demo_fphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
  "demo_femail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo*"])},
  "demo_faddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección*"])},
  "demo_fstate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado*"])},
  "demo_fcity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad*"])},
  "demo_fzipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de area*"])},
  "demo_fcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de tarjeta de crédito*"])},
  "demo_fmonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes*"])},
  "demo_fyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año*"])},
  "btn_demo_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar demo"])},
  "demo_txt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para solicitar una prueba gratis de TMP, debe ingresar una tarjeta de crédito válida y darnos su información real para así poder verificar su identidad."])},
  "demo_txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importante: Su registro a TPM® será para ingresar al demo por 7 días-. Para más informacion revise nuestro acuerdo de Términos y Condiciones."])},
  "demo_txt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se haran cargos a su tarjeta de credito por ningun motivo sin su autorizacion, ni se guardará la información de la misma."])},
  "demo_txt4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conoce 'The Perfect Classmate'; El mejor programa educativo bilingüe del mercado, diseñado para ayudar a tus hijos a mejorar en sus estudios.."])},
  "demo_txt5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROGRAMA EDUCATIVO BILINGÜE DESDE PREESCOLAR HASTA BACHILLERATO, PREPARADO DE ACUERDO CON LOS ESTÁNDARES BÁSICOS COMUNES."])},
  "career_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oportunidades de Empleo"])},
  "career_join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únete a nuestro equipo!"])},
  "career_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El equipo de TPM incluye personas dedicadas, afectuosas, entusiasmadas y motivadas. Les apasiona ayudar a las familias a llevar a sus hijos a la universidad y a las oportunidades que la universidad puede brindar. El equipo trabaja maravillosamente juntos y contratamos personas con el mismo tipo de pasión, impulso y dedicación para ayudar a los niños a alcanzar su objetivo de la universidad. Vea a continuación cualquier apertura. Si no tenemos vacantes actuales, pero aún desea compartir su currículum con nosotros, envíelo a:"])},
  "career_sales_representative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representante de ventas"])},
  "career_sales_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager de ventas"])},
  "career_sm_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabajando con TPM®, tienes el beneficio y libertad de poder ser tu propio jefe, trabajar y lograr las metas que tú te propongas."])},
  "career_sm_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sé tu propio jefe"])},
  "career_sm_offer_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te Ofrecemos"])},
  "career_sm_offer_txt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citas calificadas sin costo"])},
  "career_sm_offer_txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% de aprobaciones"])},
  "career_sm_offer_txt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito para todo nivel"])},
  "career_sm_offer_txt4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con sólo 3 niveles de excelentes comisiones."])},
  "career_sm_requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿ERES TÚ EL QUE TIENE ESTOS REQUISITOS?"])},
  "career_requirements_txt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En TPM® trabajamos solo con vendedores profesionales que:"])},
  "career_requirements_txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominan las referencias."])},
  "career_requirements_txt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizar presentaciones exitosas."])},
  "career_requirements_txt4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconocer el éxito de otros y aprender de ello."])},
  "career_requirements_txt5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escuchar las necesidades del cliente."])},
  "career_requirements_txt6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominar las objeciones y el rechazo."])},
  "career_requirements_txt7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizar la tecnología a su favor."])},
  "career_requirements_txt8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saber cerrar."])},
  "career_requirements_txt9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencia."])},
  "team_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro Equipo"])},
  "member_luccas_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departamento de soporte"])},
  "member_luccas_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nombre es Luccas Medina, soy el encargado de realizar las instalaciones y servicio al cliente. Actualmente tengo 4 años de experiencia com ingeniero en networking. Mis pasatiempos generalmente consisten en cantar y jugar basket. En mi tiempo libre disfruto de los videojuegos y leer. Sobre mis gustos literarios, mis libros favoritos son: 11 anillos y mentalidad de Mamba. "])},
  "member_luccas_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio- Gerente del departamento de instalación"])},
  "member_luccas_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
  "member_luccas_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 8635 Florence Ave. S-205 Downey, CA 90240"])},
  "member_luccas_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_luccas_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_luccas_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_edgar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representante educativo"])},
  "member_edgar_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nombre es Erick Lopez Rodriguez, soy asesor de educación. Actualmente tengo 3 años como maestro/consejero SDA chruch, 8 años como vendedor de educación. Mis pasatiempos generalmente consisten en cantar, tocar guitarra. Sobre mis gustos literarios , mis libros favoritos son: La Biblia, El deseado de todos los siglos, El conflicto de todos los siglos y 3 pequeñas mujeres."])},
  "member_edgar_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chavez Services"])},
  "member_edgar_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono:"])},
  "member_edgar_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Downey, CA 90240"])},
  "member_edgar_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo eléctronico "])},
  "member_edgar_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web "])},
  "member_edgar_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_eduardo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representante educativo"])},
  "member_eduardo_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Studia"])},
  "member_eduardo_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono:"])},
  "member_eduardo_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_eduardo_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo eléctronico "])},
  "member_eduardo_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web "])},
  "member_eduardo_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_erick_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asesor de educación"])},
  "member_erick_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nombre es Erick Lopez Rodriguez, soy asesor de educación. Actualmente tengo 3 años como maestro/consejero SDA chruch, 8 años como vendedor de educación. Mis pasatiempos generalmente consisten en cantar, tocar guitarra. Sobre mis gustos literarios , mis libros favoritos son: La Biblia, El deseado de todos los siglos, El conflicto de todos los siglos y 3 pequeñas mujeres."])},
  "member_erick_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compañia: Erick Education"])},
  "member_erick_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono:"])},
  "member_erick_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_erick_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo eléctronico "])},
  "member_erick_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web "])},
  "member_erick_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_elizabeth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representante educativo"])},
  "member_elizabeth_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nombre es Erick Lopez Rodriguez, soy asesor de educación. Actualmente tengo 3 años como maestro/consejero SDA chruch, 8 años como vendedor de educación. Mis pasatiempos generalmente consisten en cantar, tocar guitarra. Sobre mis gustos literarios , mis libros favoritos son: La Biblia, El deseado de todos los siglos, El conflicto de todos los siglos y 3 pequeñas mujeres."])},
  "member_elizabeth_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E Cruz Services"])},
  "member_elizabeth_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono:"])},
  "member_elizabeth_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_elizabeth_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo eléctronico "])},
  "member_elizabeth_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web "])},
  "member_elizabeth_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_gricelda_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representante educativo "])},
  "member_gricelda_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nombre es Gricelda Lopez, soy representante ejecutivo. Tengo 20 años de experiencia como supervisora en el área educativa, hace 5 años visitando familias para orientarlos de cómo ayudar a sus hijos a que un día logren llegar a la universidad.Mis pasatiempos generalmente consisten en pasear en bicicleta por la playa, disfrutar de mi familia, cocinar lo que les gusta, pasear mis perritas. Sobre mis gustos literarios, mis libros favoritos son: Tus zonas erróneas, Piense, el camino del lobo.  "])},
  "member_gricelda_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Gricelda E Services"])},
  "member_gricelda_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono:"])},
  "member_gricelda_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_gricelda_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo eléctronico "])},
  "member_gricelda_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web "])},
  "member_gricelda_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_griselda_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representante educativo "])},
  "member_griselda_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nombre es Gricelda Lopez, soy representante ejecutivo. Tengo 20 años de experiencia como supervisora en el área educativa, hace 5 años visitando familias para orientarlos de cómo ayudar a sus hijos a que un día logren llegar a la universidad.Mis pasatiempos generalmente consisten en pasear en bicicleta por la playa, disfrutar de mi familia, cocinar lo que les gusta, pasear mis perritas. Sobre mis gustos literarios, mis libros favoritos son: Tus zonas erróneas, Piense, el camino del lobo.  "])},
  "member_griselda_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guerrero Services"])},
  "member_griselda_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono:"])},
  "member_griselda_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_griselda_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo eléctronico "])},
  "member_griselda_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web "])},
  "member_griselda_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_patricia_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representante educativo "])},
  "member_patricia_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nombre es Patricia Linares, soy representante educativo. Tengo 15 años de experiencia supervisando y entrenando grupos y 6 años como asesora educativa. Mis pasatiempos generalmente consisten en la Jardineria y salir de compras. En mi tiempo libre isfruto una buena cena y poder caminar por la costa. Sobre mis gustos literarios, mis libros favoritos son: El poder de la metamorfosis de Salvador Carrion;novelas de Isabel Allende entre otros."])},
  "member_patricia_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" P. Lindsay"])},
  "member_patricia_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono:"])},
  "member_patricia_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_patricia_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_patricia_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_patricia_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "footer_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuidor autorizado de TPM"])},
  "request_contact_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctanos"])},
  "request_contact_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dejanos tus datos para poder realizar una demostración de nuestro producto."])},
  "request_contact_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
  "request_thks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias!"])},
  "request_thks_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronto un representate se comunicará contigo para atender tu solicitud!"])},
  "request_tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inténtalo nuevamente"])},
  "request_tryAgain_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pudimos registrar tus datos, por favor intentalo nuevamente o contáctanos via"])},
  "btn_sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrate"])}
}